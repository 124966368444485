@font-face {
  font-family: "sansation";
  src: url("../fonts/Sansation/sansation.ttf");
}

div > .activeButtonTop {
  background-color: var(--clr__secondary);
  color: white;
  border: 2px solid var(--clr__secondary);
}

nav > .activeIcon i,
nav > .activeIcon p,
nav > .activeIcon a,
div > .activeIcon a,
div > .activeIcon i,
div > .activeIcon p {
  color: var(--clr__primary);
}

.react-time-picker__clock {
  display: none !important;
}

.react-time-picker {
  margin-top: 0.5rem;
  width: 100%;
  height: 2.5rem;
}

.react-time-picker__inputGroup__input {
  min-width: 2.7rem !important;
  text-align: center;
  font-size: 1rem !important;
}

@media (max-width: 600px) {
  .react-time-picker {
    width: 50%;
    height: 3rem;
  }
  .react-time-picker__inputGroup__input {
    min-width: 4rem !important;
    text-align: center;
    font-size: 1rem !important;
  }
}

.css-1s2u09g-control {
  border-radius: 0 !important;
}
.css-1pahdxg-control:hover {
  border-radius: 0 !important;
  border-color: var(--clr__primary);
}

.css-1pahdxg-control:focus {
  border-radius: 0 !important;
  border-color: var(--clr__primary);
}

.react-daterange-picker__wrapper {
  border: 0 !important;
}

@container (max-width: 420px) {
  .container-admin-edit {
    flex-wrap: wrap !important;
  }
}

label {
  padding-bottom: 0.2rem;
}
