.showLanguage {
  /* top: 0 !important;
   z-index: -1; */
  animation: languagesAnim 400ms ease-in;
}

.hideLanguage {
  z-index: -1 !important;
}

@keyframes languagesAnim {
  0% {
    // top: 1rem;
    z-index: -1;
    background-color: #444444;
  }
  35% {
    z-index: -1;
    background-color: #444444;
  }
  50% {
    height: 30%;
  }
  70% {
    z-index: -1;
    height: 50%;
  }

  100% {
    height: 100%;
    top: 3rem;
  }
}
